import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase';

export const incrementUserCutouts = async (userEmail, incrementBy=1) => {
  if (!userEmail) {
    console.error("User email is required.");
    return;
  }

  const participantsDocRef = doc(db, "cutout", "participants");
  const docSnap = await getDoc(participantsDocRef);

  if (docSnap.exists()) {
    const participants = docSnap.data().participants || [];
    const participantIndex = participants.findIndex(
      (p) => p.email === userEmail
    );

    if (participantIndex !== -1) {
      participants[participantIndex].cutouts += incrementBy;

      await updateDoc(participantsDocRef, {
        participants: participants,
      });

    //   console.log(`Cutouts updated for ${userEmail}: ${participants[participantIndex].cutouts}`);
    } else {
      console.error("User not found in participants.");
    }
  } else {
    console.error("Participants document not found.");
  }
};

export const fetchUserCutouts = async (userEmail) => {
    if (!userEmail) return;
    
    const participantsDocRef = doc(db, "cutout", "participants");
    const docSnap = await getDoc(participantsDocRef);
    
    if (docSnap.exists()) {
      const participants = docSnap.data().participants || [];
      const user = participants.find(p => p.email === userEmail);
      if (user) {
        return user.cutouts || 0
      } else {
        console.error("User not found in participants.");
      }
    } else {
      console.error("Participants document not found.");
    }
  };
