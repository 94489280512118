import { useState } from 'react';
import './App.css';
import { Container } from 'react-bootstrap';
import {Login} from "./pages/Login"
import Home from "./pages/Home"
// Bringing in the GoogleOAuthProvider from the package
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Routes } from 'react-router-dom';
import ProfilePage from './pages/Profile';

function App() {
  const [user, setUser] = useState();
  const onLogin = (creds) => {
    setUser(creds)
  }
  return (
  
    <GoogleOAuthProvider clientId="527524707527-og0rnrudedv713sq0kbc3b91ckmok0sl.apps.googleusercontent.com" > 
        <Container style={{background:"black", height:"100vh"}}>
          <Routes>
            <Route path='/*' element={<Home onLogin={onLogin} user={user}/>} />
            <Route path='/login' element={<Login onLogin={onLogin} user={user}/>} />
            <Route path='/profile' element={<ProfilePage onLogin={onLogin} user={user}/>} />
          </Routes>
        </Container>
    </GoogleOAuthProvider>
      
    
  );
}



export default App;
