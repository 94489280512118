import { useEffect, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import {  db } from '../firebase';
import { jwtDecode } from "jwt-decode";
// setPersistence(auth, browserLocalPersistence).catch(console.error);


export const Login = ({ onLogin, user }) => {
  const [phone, setPhone] = useState("");
  const [userProfile, setUser] = useState(null);
  const navigate = useNavigate();
  const coCollection = collection(db, "cutout");
  
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, setUser);
  //   return () => unsubscribe();
  // }, []);

  const addOrGetParticipant = async (user) => {
    if (!user) return;

    const participantsDocRef = doc(coCollection, "participants");
    const docSnap = await getDoc(participantsDocRef);

    if (docSnap.exists()) {
      const participants = docSnap.data().participants || [];
      const existingParticipant = participants.find(
        (p) => p.email === user.email
      );

      if (existingParticipant) {
        // User exists, get their cutout count
        // console.log("User Found:", existingParticipant);
        // alert(`Welcome back ${existingParticipant.name}! Cutouts: ${existingParticipant.cutouts}`);
      } else {
        // Add new participant
        const newParticipant = {
          name: user.name || user.displayName,
          email: user.email,
          img: user.picture || user.photoURL,
          cutouts: 0,
        };

        await updateDoc(participantsDocRef, {
          participants: arrayUnion(newParticipant),
        });

        // console.log("New participant added:", newParticipant);
        // alert(`Welcome ${newParticipant.name}! Your journey starts now.`);
      }
    } else {
      console.error("Participants document not found.");
    }
  };

  const handleLoginSuccess = (credentialResponse)=>{
    const profile = credentialResponse;
    addOrGetParticipant(jwtDecode(profile?.credential));
    onLogin?.(profile);
    if (credentialResponse?.credential) {
      navigate('/home');
    }
  }


  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={() => {console.error('Login Failed')}}
      />
      <br />
    </div>
  );
};
