import {useState} from 'react';
import './Greetings.css'; // Import CSS for styling

const GreetingMessage = ({}) => {
    const [display, setDisplay] = useState(true)
    return (
        <>
            {display && <div>
                <h6 className="text-white text-center mb-2">
                    Where Leaders Rise
                    {/* Your voice shapes the future.  */}
                    {/* Together, we build transparency, accountability, and change. */}
                </h6>  
            </div>}
        </>
    );
};

export default GreetingMessage;
