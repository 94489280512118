import { jwtDecode } from "jwt-decode";
import React, {useEffect, useState} from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const UserProfileCard = (profileData ) => {
    const navigate = useNavigate();
    const [cutouts, setCutouts] = useState();
    
    const userData = profileData?.user?.credential 
  ? jwtDecode(profileData.user.credential) 
  : {};
    const { image, name, email } = userData
    

    const fetchCutouts = async () => {
      if (!email) return;
      const participantsDocRef = doc(db, "cutout", "participants");
      const docSnap = await getDoc(participantsDocRef);
      
      if (docSnap.exists()) {
        const participants = docSnap.data().participants || [];
        const user = participants.find(p => p.email === email);
        if (user) {
          setCutouts(user.cutouts);
        }
      }
    };
  
    useEffect(() => {
      fetchCutouts(); // Fetch count on profile page load
    }, [email]);

    if(!profileData.user){
      navigate("/login")
    }


  return (
    <Container className="d-flex justify-content-center align-items-center vh-100 position-relative">
        <Button 
style={{ backgroundColor: "#f8b400", color: "#000", border: "2px solid #fff", fontWeight: "bold", padding: "6px 12px", fontSize: "14px" }}
        className="position-absolute top-0 start-0 m-3 rounded"
        onClick={() => navigate("/")}
      >
        Home
      </Button>
      <Card style={{ width: "18rem", textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
        <Card.Img variant="top" src={image} alt={name} className="rounded-circle p-3" style={{ width: "100px", height: "100px", objectFit: "cover", margin: "auto" }} />
        <Card.Body>
          <Card.Title style={{ color: "#f8b400" }}>{name}</Card.Title>
          <Card.Text style={{ color: "#00d4ff" }}>{email}</Card.Text>
          <Card.Text style={{ color: "#ff4c4c" }}><strong>Cutouts:</strong> {cutouts}</Card.Text>
          <Card.Text className="mt-3" style={{ fontSize: "0.9rem", color: "#b3b3b3" }}>
            Echo Points are your way of making an impact and being rewarded for it! On Cutout, Raise your voice, the more you engage, the more Echo Points you earn.
            these points aren’t just for show! Soon, you’ll be able to redeem them for exciting rewards, exclusive products, and more.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UserProfileCard;

